import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { VideoKeyword } from '../../../../../interfaces/video/VideoKeyword.interface';

// helpers
import api from '../../../../../helpers/services/api';
import { setKeywords } from '../../../../../actions/video.actions';
import { applyDefaultKeywordGroups } from '../../../../../helpers/videos/applyDefaultKeywordGroups';
import { fillMissingKeywordGroupsLines } from '../../../../../helpers/videos/fillMissingKeywordGroupsLines';

// components
import { KeywordLine } from './keywordLine/KeywordLine';

interface KeywordGroup {
    group: number;
    keywords: VideoKeyword[];
}

export const KeywordsBox = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const video = useSelector((state: Selector) => state.video);

    const [isGeneratingSuggestion, setIsGeneratingSuggestion] = useState(false);
    const [keywordList, setKeywordList] = useState<VideoKeyword[]>(video.keywords);
    const [keywordGroups, setKeywordGroups] = useState<KeywordGroup[]>([]);

    const prevVideoId = useRef(video.id);

    // effect to set the keyword list when a new video is selected
    useEffect(() => {
        if (prevVideoId.current !== video.id) {
            let keywords = video.keywords;
            if (keywords.length === 0) {
                keywords = applyDefaultKeywordGroups();
            } else {
                keywords = fillMissingKeywordGroupsLines(keywords);
            }
            setKeywordList(keywords);
            prevVideoId.current = video.id;
        }
    }, [video.id, video.keywords]);

    // effect to update keyword groups when the list changes
    useEffect(() => {
        const groups: KeywordGroup[] = keywordList.reduce((acc: any, item) => {
            if (!acc[item.group - 1]) {
                acc[item.group - 1] = { group: item.group, keywords: [] };
            }
            acc[item.group - 1].keywords.push(item);
            return acc;
        }, []);
        setKeywordGroups(groups);
    }, [keywordList]);

    // handler to update the keyword list when some line is edited
    const handleUpdateKeywordLine = (keyword: VideoKeyword, text: string) => {
        const updatedKeywords: VideoKeyword[] = keywordList.map(k => {
            if (k.group === keyword.group && k.line === keyword.line) {
                return {
                    ...keyword,
                    text,
                };
            } else {
                return k;
            }
        });
        setKeywordList(updatedKeywords);
        dispatch(setKeywords(updatedKeywords));
    };

    const handleSuggestHighlights = async () => {
        if (video.parts.length > 0 && video.parts[0]?.content && video.parts[0]?.content !== '') {
            setIsGeneratingSuggestion(true);
            const suggestedKeywords = await api.video.suggestHighlights(video.parts[0]?.content);
            const keywordSuggestions: VideoKeyword[] = await suggestedKeywords.json();

            setIsGeneratingSuggestion(false);
            setKeywordList(keywordSuggestions);
        }
    };

    // add a new group to the list, by updating the keyword list
    const handleHighlightGroupAdd = () => {
        const lastGroup = keywordList[keywordList.length - 1];
        const updatedKeywordList = keywordList.map(k => k);
        for (let i = 0; i < 3; i++) {
            updatedKeywordList.push({
                id: 0,
                group: lastGroup.group + 1,
                line: i + 1,
                text: '',
            });
        }
        setKeywordList(updatedKeywordList);
    };

    return (
        <Box width="100%">
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 4,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    padding: 3,
                }}
            >
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant="h6" color="primary" sx={{ mb: 3 }}>
                        Keywords
                    </Typography>

                    <Tooltip title="Keywords Assistant">
                        <span>
                            {isGeneratingSuggestion && <CircularProgress />}
                            {!isGeneratingSuggestion && (
                                <IconButton
                                    aria-label="AI-assistant"
                                    data-test="btn-AI-assistant"
                                    onClick={() => handleSuggestHighlights()}
                                    color="primary"
                                    disabled={video.parts[0]?.content === ''}
                                    size="large"
                                    sx={{
                                        mt: -1,
                                    }}
                                >
                                    <AutoAwesomeIcon />
                                </IconButton>
                            )}
                        </span>
                    </Tooltip>
                </Stack>

                <Stack key={`keyword-group-stack`} direction="row" spacing={1} sx={{ flexGrow: 1, width: '100%' }}>
                    <Box sx={{ width: '100%', py: 1 }}>
                        <Stack direction="column" spacing={1}>
                            {keywordGroups?.map(group => (
                                <Stack direction="column" spacing={1} key={`keyword-group-${group.group}`}>
                                    <Typography
                                        key={`keyword-group-${group.group}-typography`}
                                        variant="body1"
                                        color={theme.palette.secondary.dark}
                                        sx={{ fontWeight: 'bold', pt: 3 }}
                                    >
                                        Group {group.group}
                                    </Typography>

                                    <Stack key={`keyword-group-${group.group}-stack`} direction={'column'} spacing={0}>
                                        {group.keywords.map((keyword: VideoKeyword, index: number) => (
                                            <KeywordLine
                                                key={`keyword-group-${group.group}-keyword-${index}`}
                                                keyword={keyword}
                                                handleUpdateKeywordLine={handleUpdateKeywordLine}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Box>
                </Stack>

                <Grid container>
                    <Grid item xs={12} sx={{ pb: 0, textAlign: 'center' }}>
                        <Tooltip title="Add group">
                            <Box>
                                <IconButton onClick={handleHighlightGroupAdd}>
                                    <AddCircleIcon color="primary" sx={{ fontSize: '40px' }} />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
