import { useEffect, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// mui
import { Box, CircularProgress, ClickAwayListener, IconButton, Stack, SxProps, Tooltip } from '@mui/material';
import Link from '@mui/material/Link';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import CodeIcon from '@mui/icons-material/Code';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { VideoToCreateBody, VideoUpdateBody } from '../../../../../interfaces/services/api.interfaces';

// store
import { store } from '../../../../../store/store';

// actions
import {
    setVideoUrl,
    setGenerationStatus,
    updateVideo,
    createVideo,
    deleteActiveVideo,
} from '../../../../../actions/video.actions';
import { newVideoGenerated } from '../../../../../actions/socket.actions';
import {
    showNotPermanentErrorSnackBar,
    showNotPermanentSuccessSnackBar,
    showPermanentSuccessSnackBar,
} from '../../../../../actions/snackBar.actions';

// helpers
import api from '../../../../../helpers/services/api';
import { generateVideoSaveBody } from '../../../../../helpers/videos/generateVideoSaveBody';

const styles: SxProps = {
    position: 'absolute',
    width: '300px',
    borderRadius: 3,
    top: 35,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid',
    borderColor: 'lightgray',
    p: 1,
    bgcolor: 'background.paper',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    color: 'gray',
    cursor: 'pointer',
};

export const VideoButtons = () => {
    const dispatch = useDispatch();

    const video = useSelector((state: Selector) => state.video);
    const socketVideoGenerated = useSelector((state: Selector) => state.socket.newVideoGeneration);

    const [isCreating, setIsCreating] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isGeneratingBlob, setIsGeneratingBlob] = useState(false);
    const [openShare, setOpenShare] = useState(false);

    const handleVideoGeneration = async () => {
        setIsGenerating(true);
        const audioResult = await api.audio.generateVideoAudio(video.id);
        if (audioResult.status >= 400) {
            setIsGenerating(false);
            const body = await audioResult.json();
            store.dispatch(showNotPermanentErrorSnackBar(body.error));
            throw body.error;
        }
        const videoResult = await api.video.generateVideo(video.id);
        if (videoResult.status >= 400) {
            setIsGenerating(false);
            const body = await videoResult.json();
            store.dispatch(showNotPermanentErrorSnackBar(body.error));
            throw body.error;
        }
    };

    const handleVideoDownload = async () => {
        setIsGeneratingBlob(true);

        let videoBlob: Blob = await fetch(video?.videoMediaFile?.url || '').then(r => r.blob());
        setIsGeneratingBlob(false);

        // create url for this blob.
        const videoBlobURL = URL.createObjectURL(videoBlob);

        const link = document.createElement('a');
        link.href = videoBlobURL;
        link.download = video.fileName + '.mp4';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(videoBlobURL);
    };

    const handleVideoShare = () => {
        setOpenShare(prev => !prev);
    };

    const handleClickAway = () => {
        setOpenShare(false);
    };

    const handleNew = () => {
        setIsCreating(true);
        setOpenShare(false);

        const defaultVideoToCreate: VideoToCreateBody = {
            name: 'Unnamed video',
            languageCode: video.language.code,
            audioScriptText: 'Audio script sample text',
            voiceName: 'Laurie',
            outroImageUrl: '',
            outroInfoEmail: '',
            outroInfoName: '',
            outroInfoPhone: '',
            assignJingle: false,
            assignBackground: false,
            images: [],
            keywords: [],
        };

        createVideo(defaultVideoToCreate)
            .then(action => {
                dispatch(action);
                setIsCreating(false);
                return 'ok';
            })
            .catch(e => {
                setIsSaving(false);
                Swal.fire('Error', e.message, 'error');
                return 'error';
            });
    };

    const handleSave = () => {
        setOpenShare(false);
        setIsSaving(true);

        const videoUpdateBody: VideoUpdateBody = generateVideoSaveBody(video);

        updateVideo(videoUpdateBody)
            .then(action => {
                dispatch(action);
                dispatch(showNotPermanentSuccessSnackBar('Video data was saved'));
                setIsSaving(false);
                return 'ok';
            })
            .catch(e => {
                Swal.fire('Error', e.message, 'error');
                setIsSaving(false);
                return 'error';
            });
    };

    const handleDelete = () => {
        deleteActiveVideo(video.id)
            .then(action => {
                dispatch(action);
                return 'ok';
            })
            .catch(e => {
                Swal.fire('Error', e.message, 'error');
                return 'error';
            });
    };

    // efect to handle new video selection
    useEffect(() => {
        // reset button's states
        setIsCreating(false);
        setIsSaving(false);

        if (video?.videoMediaFile?.generationStatus === 'GENERATING') {
            setIsGenerating(true);
        } else {
            setIsGenerating(false);
        }
        setIsGeneratingBlob(false);
    }, [video.id, video.videoMediaFile?.generationStatus]);

    // effect to handle socket video generation notification
    useEffect(() => {
        if (socketVideoGenerated > 0 && socketVideoGenerated === video.id) {
            dispatch(showPermanentSuccessSnackBar('Video was successfully generated'));
            setIsGenerating(false);

            // reset socket video generation flag
            dispatch(newVideoGenerated(-1));
            dispatch(setVideoUrl(video?.videoMediaFile?.url || ''));
        }
        // eslint-disable-next-line
    }, [socketVideoGenerated]);

    useEffect(() => {
        if (video.isGeneratingVideo !== isGenerating) {
            dispatch(setGenerationStatus(isGenerating));
        }
    }, [isGenerating, video.isGeneratingVideo, dispatch]);

    return (
        <Stack direction="row" spacing={2}>
            <Tooltip title="New video">
                <Box>
                    <IconButton
                        aria-label="new video"
                        color="primary"
                        disabled={isCreating || isSaving || isGenerating}
                        onClick={handleNew}
                    >
                        {isCreating ? <CircularProgress size={24} /> : <AddCircleIcon />}
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title="Save video">
                <Box>
                    <IconButton
                        aria-label="save video"
                        color="primary"
                        disabled={video.id <= 0 || isCreating || isSaving || isGenerating}
                        onClick={handleSave}
                    >
                        {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title="Generate video">
                <Box>
                    <IconButton
                        aria-label="generate video"
                        color="primary"
                        disabled={video.id <= 0 || isCreating || isSaving || isGenerating}
                        onClick={handleVideoGeneration}
                    >
                        {isGenerating ? <CircularProgress size={24} /> : <VideoCameraFrontIcon />}
                    </IconButton>
                </Box>
            </Tooltip>

            <Tooltip title="Download video">
                <Box>
                    <LoadingButton
                        aria-label="download video"
                        color="primary"
                        disabled={
                            video.id <= 0 ||
                            isCreating ||
                            isSaving ||
                            isGenerating ||
                            isGeneratingBlob ||
                            !video.videoMediaFile?.url?.includes('http')
                        }
                        onClick={handleVideoDownload}
                        loading={isGeneratingBlob}
                    >
                        <DownloadIcon />
                    </LoadingButton>
                </Box>
            </Tooltip>

            <Tooltip title="Embed video">
                <Box>
                    <IconButton
                        aria-label="Embed video"
                        color="primary"
                        disabled={
                            video.id <= 0 ||
                            isCreating ||
                            isSaving ||
                            isGenerating ||
                            !video.videoMediaFile?.url?.includes('http')
                        }
                    >
                        <CodeIcon />
                    </IconButton>
                </Box>
            </Tooltip>

            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: 'relative' }}>
                    <Tooltip title="Share video">
                        <Box>
                            <IconButton
                                aria-label="share video"
                                color="primary"
                                disabled={
                                    video.id <= 0 ||
                                    isCreating ||
                                    isSaving ||
                                    isGenerating ||
                                    !video.videoMediaFile?.url?.includes('http')
                                }
                                onClick={handleVideoShare}
                                sx={{ cursor: 'pointer' }}
                            >
                                <ShareIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>

                    {openShare ? (
                        <Box sx={styles}>
                            <Link rel="noopener noreferrer" target="_blank" href={video.videoMediaFile?.url}>
                                {video.videoMediaFile?.url}
                            </Link>
                        </Box>
                    ) : null}
                </Box>
            </ClickAwayListener>

            <Tooltip title="Delete video">
                <Box>
                    <IconButton
                        aria-label="delete video"
                        color="primary"
                        disabled={video.id <= 0 || isCreating || isSaving || isGenerating}
                        onClick={handleDelete}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Tooltip>
        </Stack>
    );
};
