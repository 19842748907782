import React, { useState, useEffect, useCallback } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Headphones from '@mui/icons-material/Headphones';
import OndemandVideo from '@mui/icons-material/OndemandVideo';
import MenuBook from '@mui/icons-material/MenuBook';
import ListIcon from '@mui/icons-material/List';

// components
import { AudioFilesTab } from './audioFilesTab/AudioFilesTab';
import { LexiconTab } from './lexiconTab/LexiconTab';
import { PlaylistTab } from './playlistTab/PlaylistTab';
import { VideoFilesTab } from './videoFilesTab/VideoFilesTab';

// actions
import { articleInfosUpToDate, videoInfosUpToDate } from '../../../../actions/socket.actions';
import { loadFiles, updateArticle } from '../../../../actions/files.actions';
import { loadPlaylists } from '../../../../actions/playlist.action';
import { sidebarTabChange } from '../../../../actions/sidebar.actions';
import { refreshVideoFiles } from '../../../../actions/video.actions';

// interfaces
import { Selector } from '../../../../interfaces/Selector.interface';
import { loadVideoFiles } from '../../../../actions/videoFiles.actions';

interface Props {
    children: any;
    index: number;
    value: number;
}

const TabPanel = (props: Props) => {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`sidebar-tabpanel-${index}`}
            key={`sidebar-tabpanel-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const defaultTabs = [
    {
        index: 0,
        name: 'audio',
        enabled: true,
        Icon: <Headphones />,
        Component: (props: any) => <AudioFilesTab {...props} />,
    },
    {
        index: 1,
        name: 'video',
        enabled: false,
        Icon: <OndemandVideo />,
        Component: (props: any) => <VideoFilesTab {...props} />,
    },
    {
        index: 2,
        name: 'lexicon',
        enabled: false,
        Icon: <MenuBook />,
        Component: (props: any) => <LexiconTab {...props} />,
    },
    {
        index: 3,
        name: 'playlist',
        enabled: false,
        Icon: <ListIcon />,
        Component: (props: any) => <PlaylistTab {...props} />,
    },
];

export const Sidebar = React.memo(() => {
    const dispatch = useDispatch();

    const articleInfosUpdated = useSelector((state: Selector) => state.socket.articleInfosUpdated);
    const videoInfosUpdated = useSelector((state: Selector) => state.socket.videoInfosUpdated);
    const role = useSelector((state: Selector) => state.auth.role);
    const videoAuthorisation = useSelector((state: Selector) => state.auth.videoAuthorisation);
    const files = useSelector((state: Selector) => state.files);
    const videoFiles = useSelector((state: Selector) => state.videoFiles);
    const dateFilter = useSelector((state: Selector) => state.sidebar.dateFilter);

    const [tabsState, setTabsState] = useState(defaultTabs);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [fetchingFiles, setFetchingFiles] = useState(false);

    const loadFilesFromTab = useCallback(
        (tabName: string) => {
            switch (tabName) {
                case 'audio':
                    loadFiles(dateFilter).then(action => dispatch(action));
                    break;

                case 'video':
                    setFetchingFiles(true);
                    loadVideoFiles(dateFilter).then(action => {
                        dispatch(action);
                        setFetchingFiles(false);
                    });
                    break;

                case 'playlist':
                    loadPlaylists().then(action => dispatch(action));
                    break;

                default:
                    break;
            }
        },
        [dateFilter, dispatch],
    );

    // effect to set the tabs visibility on the first render
    useEffect(() => {
        const newTabsState = tabsState.map(t => {
            switch (t.name) {
                case 'audio':
                    return t;
                case 'video':
                    return { ...t, enabled: videoAuthorisation };
                case 'lexicon':
                    return { ...t, enabled: role === 'Admin' };
                default:
                    return { ...t, enabled: role === 'SuperAdmin' };
            }
        });
        setTabsState(newTabsState);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (articleInfosUpdated !== -1) {
            updateArticle(articleInfosUpdated, files).then(action => {
                dispatch(action);
                // after throwing article update with article ID, clean the state (article infos are up to date)
                dispatch(articleInfosUpToDate());
            });
            loadPlaylists().then(action => dispatch(action));
        }
        // eslint-disable-next-line
    }, [articleInfosUpdated]);

    useEffect(() => {
        if (videoInfosUpdated !== -1) {
            refreshVideoFiles(videoInfosUpdated, videoFiles).then(action => {
                dispatch(action);
                // after throwing video update with video ID, clean the state (video infos are up to date)
                dispatch(videoInfosUpToDate());
            });
        }
        // eslint-disable-next-line
    }, [videoInfosUpdated]);

    // load files on first render
    useEffect(() => {
        const selectedTab = tabsState.filter(t => t.index === selectedTabIndex)[0];
        loadFilesFromTab(selectedTab?.name);
        // eslint-disable-next-line
    }, [selectedTabIndex]);

    useEffect(() => {
        const tabFilter = tabsState.filter(tab => tab.index === selectedTabIndex);
        if (tabFilter.length > 0) {
            dispatch(
                sidebarTabChange({
                    selectedTabName: tabFilter[0]?.name,
                }),
            );
        }
    }, [tabsState, selectedTabIndex, dispatch]);

    // reload files when dateFilter changes
    useEffect(() => {
        const selectedTab = tabsState.filter(t => t.index === selectedTabIndex)[0];
        loadFilesFromTab(selectedTab?.name);
        // eslint-disable-next-line
    }, [dateFilter]);

    const handleChange = (event: any, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    return (
        <>
            <Box
                key="selector"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'background.paper',
                }}
            >
                <Tabs
                    aria-label="sidebar-tabs"
                    data-test="sidebar-tabs"
                    key="sidebar-tabs"
                    onChange={handleChange}
                    value={selectedTabIndex}
                    sx={{ display: 'flex', flex: 1 }}
                >
                    {tabsState.map(tab => (
                        <Tab
                            aria-label={tab.name}
                            aria-controls={`sidebar-tab-${tab.index}`}
                            data-test={`sidebar-tab-${tab.name.toLowerCase()}`}
                            icon={tab.Icon}
                            id={`sidebar-tab-${tab.index}`}
                            key={`sidebar-tab-${tab.index} `}
                            label={tab.name}
                            sx={{
                                flex: 1, // Each tab takes up equal width
                                minWidth: '20px',
                                display: tab.enabled ? 'flex' : 'none',
                            }}
                        />
                    ))}
                </Tabs>
            </Box>

            {tabsState.map(tab => (
                <TabPanel value={selectedTabIndex} index={tab.index} key={`tabPanel${tab.index}`}>
                    {tab.Component({ fetchingFiles })}
                </TabPanel>
            ))}
        </>
    );
});
