import { useCallback, useEffect, useState } from 'react';

// components
import ImageUploading from 'react-images-uploading';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import EditIcon from '@mui/icons-material/Edit';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { VideoImage } from '../../../../../interfaces/video/VideoImage.interface';

// actions
import { setVideoImages, updateVideo } from '../../../../../actions/video.actions';
import { VideoUpdateBody } from '../../../../../interfaces/services/api.interfaces';
import { generateVideoSaveBody } from '../../../../../helpers/videos/generateVideoSaveBody';

export const ImageUploadBox = () => {
    const theme = useTheme();
    const maxNumberOfImages = 20;

    const dispatch = useDispatch();
    const video = useSelector((state: Selector) => state.video);

    const [pictures, setPictures] = useState<VideoImage[]>([]);
    const [savePictures, setSavePictures] = useState(true);

    const updatePhotos = useCallback((photos: VideoImage[]): VideoImage[] => {
        let updatedPhotos: VideoImage[] = [];
        for (let i = 0; i < photos.length; i++) {
            updatedPhotos.push({
                image: photos[i]?.image,
                id: i + 1,
                order: i + 1,
            });
        }
        return updatedPhotos;
    }, []);

    // effect to load the images from the DB when a new video is selected
    useEffect(() => {
        setSavePictures(false);
        setPictures(updatePhotos(video.images));
        // eslint-disable-next-line
    }, [video.id]);

    // effect to sync DB when the images are updated
    useEffect(() => {
        if (savePictures) {
            setSavePictures(false);
            const updatedPhotos: VideoImage[] = updatePhotos(pictures);
            dispatch(setVideoImages(updatedPhotos));

            let videoUpdateBody: VideoUpdateBody = generateVideoSaveBody(video);
            videoUpdateBody.images = updatedPhotos;

            updateVideo(videoUpdateBody)
                .then(action => {
                    dispatch(action);
                    return 'ok';
                })
                .catch(e => {
                    return 'error';
                });
        }
        // eslint-disable-next-line
    }, [savePictures]);

    const handleImageUpload = (imageList: any) => {
        setPictures(imageList);
        setSavePictures(true);
    };

    const onImageUp = (imageIndex: any) => {
        let updatedImages = [...pictures];
        const imageToMove = updatedImages[imageIndex];
        updatedImages.splice(imageIndex, 1);
        updatedImages.splice(imageIndex - 1, 0, imageToMove);
        setPictures(updatedImages);
        setSavePictures(true);
    };

    const onImageDown = (imageIndex: any) => {
        let updatedImages = [...pictures];
        const imageToMove = updatedImages[imageIndex];
        updatedImages.splice(imageIndex, 1);
        updatedImages.splice(imageIndex + 1, 0, imageToMove);
        setPictures(updatedImages);
        setSavePictures(true);
    };

    return (
        <Box width="100%">
            <ImageUploading
                multiple
                value={pictures}
                onChange={handleImageUpload}
                maxNumber={maxNumberOfImages}
                dataURLKey="image"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 4,
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            p: 2,
                        }}
                    >
                        <Box sx={{ alignItems: 'center', padding: 1 }}>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="h6" color="primary">
                                    Photos
                                </Typography>
                            </Stack>
                        </Box>
                        {imageList.length === 0 && (
                            <Stack direction={'row'} sx={{ p: 1 }}>
                                <Button
                                    variant="outlined"
                                    onClick={onImageUpload}
                                    disableElevation
                                    color={isDragging ? 'secondary' : 'primary'}
                                    {...dragProps}
                                    sx={{
                                        minWidth: '230px',
                                        minHeight: '230px',
                                        cursor: 'pointer',
                                        border: '3px solid',
                                        borderRadius: 3,
                                        borderColor: '#DAE2ED',
                                        borderStyle: 'dashed',
                                        mr: 1,
                                    }}
                                >
                                    <AddCircleIcon />
                                    &nbsp;
                                    <Typography>Drop a photo</Typography>
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={onImageUpload}
                                    disableElevation
                                    color={isDragging ? 'secondary' : 'primary'}
                                    {...dragProps}
                                    sx={{
                                        minWidth: '230px',
                                        minHeight: '230px',
                                        cursor: 'pointer',
                                        border: '3px solid',
                                        borderRadius: 3,
                                        borderColor: '#DAE2ED',
                                        borderStyle: 'dashed',
                                        mr: 1,
                                    }}
                                >
                                    <AddCircleIcon />
                                    &nbsp;
                                    <Typography>Drop a photo</Typography>
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={onImageUpload}
                                    disableElevation
                                    color={isDragging ? 'secondary' : 'primary'}
                                    {...dragProps}
                                    sx={{
                                        minWidth: '230px',
                                        minHeight: '230px',
                                        cursor: 'pointer',
                                        border: '3px solid',
                                        borderRadius: 3,
                                        borderColor: '#DAE2ED',
                                        borderStyle: 'dashed',
                                        mr: 4,
                                    }}
                                >
                                    <AddCircleIcon />
                                    &nbsp;
                                    <Typography>Drop a photo</Typography>
                                </Button>
                            </Stack>
                        )}
                        <ImageList cols={3} rowHeight={300}>
                            {imageList.map((image, index) => (
                                <ImageListItem key={`img-${index}`} sx={{ p: 0, alignItems: 'center' }}>
                                    <Box
                                        key={`img-box-${index}`}
                                        bgcolor={'#F1F2F4'}
                                        style={{ borderRadius: 10, width: '230px', height: '230px' }}
                                    >
                                        <img
                                            key={`img-${index}`}
                                            src={image['image']}
                                            alt={image.image}
                                            style={{
                                                borderRadius: 10,
                                                width: '230px',
                                                height: '230px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        key={`img-options-box-${image.img}`}
                                        alignItems={'center'}
                                        textAlign={'center'}
                                    >
                                        <IconButton
                                            key={`img-option-up-${image.img}`}
                                            aria-label="set image order"
                                            color="primary"
                                            disabled={index === 0}
                                            onClick={() => onImageUp(index)}
                                        >
                                            <ArrowCircleLeftIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            key={`img-option-set-${image.img}`}
                                            aria-label="set image"
                                            color="primary"
                                            onClick={() => onImageUpdate(index)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            key={`img-option-delete-${image.img}`}
                                            aria-label="delete image"
                                            color="secondary"
                                            onClick={() => onImageRemove(index)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            key={`img-option-down-${image.img}`}
                                            aria-label="set image order"
                                            color="primary"
                                            disabled={index === pictures.length - 1}
                                            onClick={() => onImageDown(index)}
                                        >
                                            <ArrowCircleRightIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </ImageListItem>
                            ))}
                        </ImageList>

                        <Grid container>
                            <Grid item xs={12} sx={{ pb: 0, textAlign: 'center' }}>
                                <Tooltip title="Add images">
                                    <Box>
                                        <IconButton onClick={onImageUpload}>
                                            <AddCircleIcon
                                                color="primary"
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: '40px',
                                                }}
                                            />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </ImageUploading>
        </Box>
    );
};
