import React, { useEffect, useState, useDeferredValue } from 'react';

// modules
import { useSelector } from 'react-redux';

// mui
import { Box, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import { VideoFileItem } from './videoFileItem/VideoFileItem';
import { DateFilterButtons } from './dateFilterButtons/DateFilterButtons';
import { ParentItemLabel } from './videoFileItem/parentItemLabel/ParentItemLabel';
import { SearchInput } from './searchInput/SearchInput';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';

// actions
import { Video } from '../../../../../interfaces/video/Video.interface';

const NoFilesLabel = () => {
    const theme = useTheme();
    return (
        <Box sx={{ p: theme.spacing(1) }}>
            <Typography color={theme.palette.text.secondary} component="div" variant="caption">
                No videos with the current filters
            </Typography>
        </Box>
    );
};

export const VideoFilesTab = React.memo(({ fetchingFiles }: { fetchingFiles: boolean }) => {
    const theme = useTheme();

    // state selector
    const videoFiles = useSelector((state: Selector) => state.videoFiles);

    const [expanded, setExpanded] = useState(['files']);
    const [filesList, setFilesList] = useState<Video[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);

    const deferredFilesList = useDeferredValue(filesList);

    const handleTreeItemNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    // effect to set the files list
    useEffect(() => {
        if (videoFiles?.length > 0) {
            const videoFilesList = videoFiles
                .filter(file => file.visible && file.status !== 'DELETED')
                .sort((a, b) => a.fileName.localeCompare(b.fileName));

            setFilesList(videoFilesList);
        } else {
            setFilesList([]);
        }
        // eslint-disable-next-line
    }, [videoFiles]);

    return (
        <>
            <Box
                id="audio-tab-header"
                sx={{
                    backgroundColor: 'background.paper',
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: theme.spacing(2),
                    position: 'sticky',
                    pt: theme.spacing(1),
                    top: 73,
                    zIndex: 1,
                    width: '100%',
                }}
            >
                <SearchInput />
                <DateFilterButtons />
            </Box>

            <TreeView
                aria-label="audio-files-tab"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={handleTreeItemNodeToggle}
                key="video-files-tab"
            >
                <TreeItem
                    data-test="audiotab-tree-files"
                    label={<ParentItemLabel Icon={ArticleIcon} text="VIDEOS" />}
                    nodeId="files"
                    key="video-tab-draft-files"
                >
                    {videoFiles?.length === 0 && fetchingFiles && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pr: 3, pt: 2 }}>
                            <CircularProgress size={20} />
                        </Box>
                    )}
                    {deferredFilesList?.length === 0 && <NoFilesLabel key="no-video-files-label" />}

                    {deferredFilesList.map((video: Video) => (
                        <Box key={`video-draft-file-box-${video.id}`}>
                            <Divider sx={{ my: theme.spacing(0.3) }} />

                            <VideoFileItem
                                deleteMode={false}
                                key={`video-draft-file-${video.id}`}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                videoItem={video}
                            />
                        </Box>
                    ))}
                </TreeItem>
            </TreeView>
        </>
    );
});
