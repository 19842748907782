import { AnyAction } from 'redux';
import { types } from '../actions/actionTypes/actionTypes'
import { SocketState } from '../interfaces/Selector.interface';

const initialState: SocketState = {
    articleInfosUpdated: -1,
    newArticleAudioGeneration: -1,
    newArticleVideoGeneration: -1,
    videoInfosUpdated: -1,
    newVideoAudioGeneration: -1,
    newVideoGeneration: -1
}

export const socketReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case types.socketArticleInfosUpdated:
            return {
                ...state,
                articleInfosUpdated: action.payload.data as number
            } as SocketState

        case types.articleInfosUpToDate:
            return {
                ...state,
                articleInfosUpdated: -1
            } as SocketState

        case types.socketNewArticleAudioGenerated:
            return {
                ...state,
                newArticleAudioGeneration: action.payload.data as number
            } as SocketState

        case types.socketVideoInfosUpdated:
            return {
                ...state,
                videoInfosUpdated: action.payload.data as number
            } as SocketState

        case types.videoInfosUpToDate:
            return {
                ...state,
                videoInfosUpdated: -1
            } as SocketState

        case types.socketNewVideoAudioGenerated:
            return {
                ...state,
                newVideoAudioGeneration: action.payload.data as number
            } as SocketState

        case types.socketNewVideoGenerated:
            return {
                ...state,
                newVideoGeneration: action.payload.data as number
            } as SocketState
        default:
            return state as SocketState
    }
}