import { Socket, io } from 'socket.io-client';

// store
import { store } from '../../store/store';

// actions
import {
    articleInfosUpdated,
    newArticleAudioGenerated,
    newVideoAudioGenerated,
    newVideoGenerated,
    videoInfosUpdated,
} from '../../actions/socket.actions';


const baseUrl: string | undefined = process.env.REACT_APP_API_URL;

const updateArticleInfos = (data: number) => {
    store.dispatch(articleInfosUpdated(data));
}

const updateArticleAudioGenerationStatus = (data: number) => {
    store.dispatch(newArticleAudioGenerated(data))
}

const updateVideoInfos = (data: number) => {
    store.dispatch(videoInfosUpdated(data));
}

const updateVideoAudioGenerationStatus = (data: number) => {
    store.dispatch(newVideoAudioGenerated(data))
}

const updateVideoGenerationStatus = (data: number) => {
    store.dispatch(newVideoGenerated(data))
}

export const createSocketClient = (): Socket | undefined => {
    if (baseUrl) {
        const token = localStorage.getItem('token');
        const socket: Socket = io(baseUrl, { auth: { token } });

        socket?.on('articleInfosUpdated', updateArticleInfos);
        socket?.on('articleAudioGenerated', updateArticleAudioGenerationStatus);
        socket?.on('videoInfosUpdated', updateVideoInfos);
        socket?.on('videoAudioGenerated', updateVideoAudioGenerationStatus);
        socket?.on('videoGenerated', updateVideoGenerationStatus);
        return socket;
    }
    return undefined;
};

export const updateSocketUser = (socket: Socket | undefined, token: string): Socket | undefined => {
    if (baseUrl) {
        socket?.disconnect();
        const newSocket = io(baseUrl, { auth: { token } });

        newSocket?.on('articleInfosUpdated', updateArticleInfos);
        newSocket?.on('articleAudioGenerated', updateArticleAudioGenerationStatus);
        newSocket?.on('videoInfosUpdated', updateVideoInfos);
        newSocket?.on('videoAudioGenerated', updateVideoAudioGenerationStatus);
        newSocket?.on('videoGenerated', updateVideoGenerationStatus);
        return newSocket;
    }
    return undefined;
};


export const destroySocketClient = (socket: Socket) => {
    socket.disconnect();
};
