import { useState, useEffect, useDeferredValue } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-world-flags';

// mui
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';

// styles
import '../../../audioEditorPage/styles/country-flags.css';

// data
import { getFlagCode } from '../../../../../../data/languageLabels';

// actions
import { loadVoices, thesaurusVoiceUpdate } from '../../../../../../actions/voices.actions';

// interfaces
import { Voice } from '../../../../../../interfaces/article/Voice.interface';
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { VideoUpdateBody } from '../../../../../../interfaces/services/api.interfaces';
import { generateVideoSaveBody } from '../../../../../../helpers/videos/generateVideoSaveBody';
import { updateVideo } from '../../../../../../actions/video.actions';
import { VideoPart } from '../../../../../../interfaces/video/VideoPart.interface';

export default function AudioScriptVoiceSelector() {
    const dispatch = useDispatch();

    // state selector
    const video = useSelector((state: Selector) => state.video);
    const selectedLanguage = useSelector((state: Selector) => state.video.language.code);
    const videoParts = useSelector((state: Selector) => state.video.parts);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);
    const [loadingVoices, setLoadingVoices] = useState(false);
    const userId = useSelector((state: Selector) => state.auth.uid);
    const deferredUserId = useDeferredValue(userId);

    const [selectedVoice, setSelectedVoice] = useState<Voice>(
        videoParts.length > 0 ? videoParts[0]?.voice : voiceList[0],
    );
    const [voices, setVoices]: [Voice[], any] = useState<Voice[]>([]);

    // effect to load voices on user change
    useEffect(() => {
        if (!loadingVoices && voiceList.length === 0) setLoadingVoices(true);
        // eslint-disable-next-line
    }, [deferredUserId]);

    useEffect(() => {
        if (loadingVoices) {
            loadVoices().then(action => {
                dispatch(action);
                setLoadingVoices(false);
            });
        }
        // eslint-disable-next-line
    }, [loadingVoices]);

    useEffect(() => {
        let filteredVoices: Voice[] = voiceList.filter(v => v.language === selectedLanguage && v.enabled);
        setVoices(filteredVoices);
        setSelectedVoice(filteredVoices[0]);
    }, [voiceList, selectedLanguage]);

    useEffect(() => {
        if (videoParts.length > 0) {
            const partVoice = videoParts[0]?.voice;
            if (partVoice) {
                setSelectedVoice(partVoice);
            }
        } else {
            setSelectedVoice(voiceList[0]);
        }
        // eslint-disable-next-line
    }, [videoParts]);

    const handleVoiceChange = (e: any) => {
        const voiceName = e.target.value;

        // get voice default parameters
        const filteredVoice = voiceList.filter(v => v.name === voiceName)[0];

        if (filteredVoice) {
            setSelectedVoice(filteredVoice);
            handleSaveVideo(filteredVoice);
            dispatch(thesaurusVoiceUpdate(filteredVoice.id));
        }
    };

    const handleSaveVideo = (filteredVoice: Voice) => {
        let videoUpdateBody: VideoUpdateBody = generateVideoSaveBody(video);
        if (videoUpdateBody?.parts?.length > 0) {
            if (videoUpdateBody.parts[0].voice !== filteredVoice) {
                const updatedParts = videoUpdateBody.parts.reduce(
                    (acc: VideoPart[], part: VideoPart, index: number) => {
                        if (index === 0) {
                            acc.push({ ...part, voice: { ...filteredVoice } });
                        } else {
                            acc.push(part);
                        }
                        return acc;
                    },
                    [],
                );
                videoUpdateBody.parts = updatedParts;

                updateVideo(videoUpdateBody)
                    .then(action => {
                        dispatch(action);
                    })
                    .catch(e => {
                        console.error('Error updating the video properties from AudioScriptBox', e);
                    });
            }
        }
    };

    return (
        <Box sx={{ textAlign: 'left', width: '50%' }}>
            <FormControl fullWidth>
                <InputLabel id="lexiconVoiceSelectorLabel">Voice</InputLabel>
                <Select
                    id="lexiconVoiceSelector"
                    data-test="lexiconvoice-selector"
                    label="Voices"
                    labelId="lexiconVoiceSelectorLabel"
                    onChange={handleVoiceChange}
                    value={selectedVoice?.name || ''}
                    fullWidth
                >
                    {voices.map(voice => (
                        <MenuItem value={voice.name} key={voice.name}>
                            <Stack direction="row" spacing={1}>
                                <Flag id="flag-img-voice" code={getFlagCode(voice.language)} fallback={<span></span>} />
                                {voice.ssmlGender === 'MALE' ? (
                                    <MaleIcon
                                        sx={{
                                            color: '#CCC',
                                            marginRight: '5px',
                                        }}
                                    />
                                ) : (
                                    <FemaleIcon
                                        sx={{
                                            color: '#CCC',
                                            marginRight: '5px',
                                        }}
                                    />
                                )}
                                <Typography
                                    component="div"
                                    sx={
                                        voice.language !== selectedLanguage
                                            ? {
                                                  color: 'rgba(0, 0, 0, 0.5)',
                                                  fontStyle: 'italic',
                                              }
                                            : {}
                                    }
                                >
                                    {voice.name}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
