/**
 * All the calls to the services are done here
 */
import { LexiconEntry } from "../../models/lexiconEntry.model";
import { externalSevices } from './fetch';

// interfaces
import {
    ArticleSaveBody,
    PlaylistToCreate,
    PlaylistToUpdate,
    UserAddBody,
    UserUpdateBody,
    VideoToCreateBody,
    VideoUpdateBody,
    VideoUpdateInfoBody,
} from "../../interfaces/services/api.interfaces";
import { VoiceToSave } from "../../interfaces/article/Voice.interface";
import { AudioPartStream } from "../../interfaces/article/ArticlePartStream.interface";


const api = {
    auth: {
        login: async (name: string, password: string) =>
            await externalSevices.withoutToken.post('auth/login', { name, password }),
        renew: async () => await externalSevices.withToken.get('auth/renew'),
        impersonate: async (userId: number) =>
            await externalSevices.withToken.post(
                `auth/impersonate?userId=${userId}`, {}, 'application/json', 'token-imp'
            )
    },
    user: {
        getAll: async (getClientInfo: boolean) =>
            await externalSevices.withToken.get(`user/getAll?getClientInfo=${getClientInfo}`),
        getAllImpersonated: async (getClientInfo: boolean) =>
            await externalSevices.withToken.get(`user/getAll?getClientInfo=${getClientInfo}`, 'token-imp'),
        getAllByClient: async (getClientInfo: boolean) =>
            await externalSevices.withToken.get(`user/getAllByClient?getClientInfo=${getClientInfo}`),
        add: async (body: UserAddBody) => await externalSevices.withToken.post('user/add', body),
        update: async (articleId: number, body: UserUpdateBody) =>
            await externalSevices.withToken.put(`user/update/${articleId}`, body),
        delete: async (articleId: number) => await externalSevices.withToken.delete(`user/delete/${articleId}`)
    },
    client: {
        getAll: async () => await externalSevices.withToken.get('client/getAll'),
        getName: async () => await externalSevices.withToken.get('client/getName'),
    },
    config: {
        getLexicon: async () => await externalSevices.withToken.get('config/lexicon'),
        getVoices: async () => await externalSevices.withToken.get('config/voices'),
        getJingles: async () => await externalSevices.withToken.get('config/jingles'),
        getBackgrounds: async () => await externalSevices.withToken.get('config/backgrounds'),
        getConsumptions: async () => await externalSevices.withToken.get('config/consumption'),
        getListenings: async () => await externalSevices.withToken.get('config/listenings'),

    },
    article: {
        getAll: async (filter: string): Promise<Response> =>
            await externalSevices.withToken.get(`article/getAll?filter=${filter}`),
        getArticleInfo: async (articleId: number) => await externalSevices.withToken.get(`article/getInfos/${articleId}`),
        get: async (articleId: number) => await externalSevices.withToken.get(`article/get/${articleId}`),
        getIntro: async () => await externalSevices.withToken.get(`article/getIntro`),
        save: async (body: ArticleSaveBody) => await externalSevices.withToken.post('article/save', body),
        create: async (body: any) => await externalSevices.withToken.post('article/create', body),
        delete: async (articleId: number) => await externalSevices.withToken.delete(`article/delete/${articleId}`),
        update: async (articleId: number, body: any) =>
            await externalSevices.withToken.post(`article/update?articleId=${articleId}`, body),
        updateImage: async (articleId: number, imageUrl: string) =>
            await externalSevices.withToken.put(`article/updateImage?articleId=${articleId}&imageUrl=${imageUrl}`, {}),
        selectVersion: async (articleId: number) =>
            await externalSevices.withToken.patch(`article/selectVersion?articleId=${articleId}`),
        translate: async (articleId: number, languageId: number, translator: string) =>
            await externalSevices.withToken.post(
                `article/translate?articleId=${articleId}&languageId=${languageId}&translator=${translator}`, {}
            ),
        updatePartSubtitle: async (articlePartId: number, body: string) =>
            await externalSevices.withToken.put(`article/updatePartSubtitle?articlePartId=${articlePartId}`, body),
        updateLanguage: async (id: number, languageCode: string) =>
            await externalSevices.withToken.put(`article/updateLanguage?id=${id}&languageCode=${languageCode}`, {}),
        publish: async (articleId: number) =>
            await externalSevices.withToken.get(`article/publish?articleId=${articleId}`),
        unpublish: async (articleId: number) =>
            await externalSevices.withToken.get(`article/unpublish?articleId=${articleId}`),
        publishAusha: async (articleId: number) =>
            await externalSevices.withToken.get(`article/publishAusha?articleId=${articleId}`),
        unpublishAusha: async (articleId: number) =>
            await externalSevices.withToken.get(`article/unpublishAusha?articleId=${articleId}`)
    },
    playlist: {
        getAll: async () => await externalSevices.withToken.get('playlist/getAll'),
        create: async (body: PlaylistToCreate) => await externalSevices.withToken.post('playlist/create', body),
        save: async (body: PlaylistToUpdate) => await externalSevices.withToken.post('playlist/update', body),
        removeArticle: async (playlistId: number, articleId: number) =>
            await externalSevices.withToken.patch(`playlist/update/remove?id=${playlistId}&articleId=${articleId}`),
        addArticle: async (articleId: number, playlistId: number) =>
            await externalSevices.withToken.patch(`playlist/update/add?id=${playlistId}&articleId=${articleId}`),
        delete: async (playlistId: number) => await externalSevices.withToken.delete(`playlist/delete?id=${playlistId}`),
    },
    voice: {
        getAll: async () => await externalSevices.withToken.get('voice/getAll'),
        add: async (body: VoiceToSave) => await externalSevices.withToken.post('voice/add', body),
        update: async (voiceId: number, body: VoiceToSave) =>
            await externalSevices.withToken.put(`voice/update?id=${voiceId}`, body),
        delete: async (voiceId: number) => await externalSevices.withToken.delete(`voice/delete?id=${voiceId}`),
    },
    audio: {
        generateArticle: async (articleId: number) =>
            await externalSevices.withToken.get(`audio/generate?articleId=${articleId}`),
        generateVideoAudio: async (videoId: number) =>
            await externalSevices.withToken.get(`audio/generateVideoScript?videoScriptId=${videoId}`),
        generateSection: async (body: AudioPartStream) =>
            await externalSevices.withToken.post('audio/generateSection', body),
        generateThesaurusAudio: async (body: any) =>
            await externalSevices.withToken.post('audio/generateThesaurusAudio', body),
        getFullAudio: async (articleId: number) =>
            await externalSevices.withToken.get(`audio/getFullAudio?articleId=${articleId}`),
        getArticleSectionAudio: async (articleSectionId: number) =>
            await externalSevices.withToken.get(`audio/getArticleSectionAudio?articleSectionId=${articleSectionId}`),
        uploadAudio: async (articleId: number, body: any) =>
            await externalSevices.withToken.post(`audio/upload?articleId=${articleId}`, body, ''),
        uploadAudioSection: async (articleId: number, body: any) =>
            await externalSevices.withToken.post(`audio/uploadSection?articleSectionId=${articleId}`, body, ''),
    },
    video: {
        getAll: async (filter: string): Promise<Response> =>
            await externalSevices.withToken.get(`video-script/getAll?filter=${filter}`),
        getVideoInfo: async (videoId: number) =>
            await externalSevices.withToken.get(`video-script/getInfos/${videoId}`),
        get: async (videoId: number) =>
            await externalSevices.withToken.get(`video-script/get/${videoId}`),
        create: async (body: VideoToCreateBody) =>
            await externalSevices.withToken.post('video-script/create', body),
        update: async (body: VideoUpdateBody) =>
            await externalSevices.withToken.post('video-script/update', body),
        updateInfo: async (body: VideoUpdateInfoBody) =>
            await externalSevices.withToken.post('video-script/update-info', body),
        updateLanguage: async (id: number, languageCode: string) =>
            await externalSevices.withToken.put(`video-script/update-language?id=${id}&languageCode=${languageCode}`, {}),
        updateOutroInfoImage: async (id: number, img: string) =>
            await externalSevices.withToken.put(`video-script/update-outro-info-image?id=${id}`, { imageBase64String: img }),
        deleteOutroInfoImage: async (id: number) =>
            await externalSevices.withToken.put(`video-script/delete-outro-info-image?id=${id}`, {}),
        generateVideo: async (videoScriptId: number) =>
            await externalSevices.withToken.get(`video-script/generateVideo?videoScriptId=${videoScriptId}`),
        suggestHighlights: async (text: string) =>
            await externalSevices.withToken.get(`video/suggestHighlights?text=${text}`),
        suggestAudioScript: async (text: string) =>
            await externalSevices.withToken.get(`video/suggestAudioScript?text=${text}`),
    },
    lexicon: {
        save: async (body: LexiconEntry) => externalSevices.withToken.post(`lexicon/save`, body),
        delete: async (lexiconId: number) => externalSevices.withToken.delete(`lexicon/delete?id=${lexiconId}`),
        buildThesaurusData: async (body: number[]) => externalSevices.withToken.post(`lexicon/buildThesaurusData`, body)
    },
    jingle: {
        getAll: async () => externalSevices.withToken.get('jingle/getAll'),
        delete: async (jingleId: number) => externalSevices.withToken.delete(`jingle/delete?id=${jingleId}`),
        saveAll: async (body: any) => externalSevices.withToken.post('jingle/saveAll', body),
        save: async (body: any) => externalSevices.withToken.post('jingle/save', body),
        upload: async (body: any) => externalSevices.withToken.post('jingle/upload', body, ''),
    },
    background: {
        getAll: async () => externalSevices.withToken.get('background/getAll'),
        delete: async (jingleId: number) => externalSevices.withToken.delete(`background/delete?id=${jingleId}`),
        saveAll: async (body: any) => externalSevices.withToken.post('background/saveAll', body),
        save: async (body: any) => externalSevices.withToken.post('background/save', body),
        upload: async (body: any) => externalSevices.withToken.post('background/upload', body, ''),
    },
    highlight: {
        getThesaurusCandidates: async (articleId: number) =>
            await externalSevices.withToken.get(`highlight/getThesaurusCandidates?articleId=${articleId}`),
        getLiaisonsCandidates: async (text: string) =>
            await externalSevices.withToken.post(`highlight/getLiaisonsCandidates`, { text })
    },
    language: {
        getAll: async () => await externalSevices.withToken.get('languages/getAll'),
    },
    consumption: {
        getConsumptionGenerated: async (startDate: String, endDate: String) =>
            await externalSevices.withToken.get(
                `consumption/getClientAudioGenerationConsumption/${startDate}/${endDate}`
            ),
        getConsumptionGeneratedByPublisher: async (startDate: String, endDate: String, publisher: String) =>
            await externalSevices.withToken.get(
                `consumption/getClientPublisherAudioGenerationConsumption/${startDate}/${endDate}/${publisher}`
            ),
        getConsumptionStreamed: async (startDate: String, endDate: String) =>
            await externalSevices.withToken.get(
                `consumption/getClientAudioStreamingConsumption/${startDate}/${endDate}`
            ),
        getConsumptionStreamedByPublisher: async (startDate: String, endDate: String, publisher: String) =>
            await externalSevices.withToken.get(
                `consumption/getClientPublisherAudioStreamingConsumption/${startDate}/${endDate}/${publisher}`
            ),
        getArticleGenerationCount: async (startDate: String, endDate: String) =>
            await externalSevices.withToken.get(`consumption/getArticleGenerationCount/${startDate}/${endDate}`
            ),
        getPublisherArticleGenerationCount: async (startDate: String, endDate: String, publisherName: String) =>
            await externalSevices.withToken.get(`consumption/getPublisherArticleGenerationCount/${startDate}/${endDate}/${publisherName}`
            ),
        getLongArticleGenerationCount: async (lengthThreshold: Number, startDate: String, endDate: String) =>
            await externalSevices.withToken.get(
                `consumption/getLongArticleGenerationCount/${lengthThreshold}/${startDate}/${endDate}`
            ),
        getPublisherLongArticleGenerationCount: async (lengthThreshold: Number, startDate: String, endDate: String, publisherName: String) =>
            await externalSevices.withToken.get(
                `consumption/getPublisherLongArticleGenerationCount/${lengthThreshold}/${startDate}/${endDate}/${publisherName}`
            ),
    },
    metrics: {
        getMetrics: async (startDate: String, endDate: String) =>
            await externalSevices.withToken.get(`playerMetrics/getPlayerMetrics/${startDate}/${endDate}`),
    }
};

export default api;
