import { useState, useEffect } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// components
import AudioScriptVoiceSelector from './audioScriptVoiceSelector/AudioScriptVoiceSelector';
import { AudioScriptLanguageSelector } from './audioScriptLanguageSelector/AudioScriptLanguageSelector';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    Modal,
    Stack,
    TextareaAutosize,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { Player } from '../../../../generics/player/Player';
import { playerDefaultState } from '../../../../../state/playerDefaultState';
import { getStreamDefaultState } from '../../../../../state/streamDefaultState';
import { Selector } from '../../../../../interfaces/Selector.interface';
import { handlePlayPauseThesaurus } from '../../../../generics/player/playerHandlers';
import { Voice } from '../../../../../interfaces/article/Voice.interface';
import { thesaurusVoiceUpdate } from '../../../../../actions/voices.actions';
import { setAudioScript, updateVideo } from '../../../../../actions/video.actions';
import api from '../../../../../helpers/services/api';
import { VideoPart } from '../../../../../interfaces/video/VideoPart.interface';
import { VideoUpdateBody } from '../../../../../interfaces/services/api.interfaces';
import { generateVideoSaveBody } from '../../../../../helpers/videos/generateVideoSaveBody';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(TextareaAutosize)(
    ({ theme }) => `
box-sizing: border-box;
width: 100%;
font-family: sans-serif;
font-size: 0.875rem;
font-weight: 400;
line-height: 1.5;
padding: 8px 12px;
border-radius: 8px;
color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
background: ${theme.palette.mode === 'dark' ? grey[900] : theme.palette.info.light};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
box-shadow: 0 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
&:hover {
  border-color: ${blue[400]};
}
&:focus {
  border-color: ${blue[400]};
  box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
}
/* firefox */
&:focus-visible {
  outline: 0;
}
`,
);

export const AudioScriptBox = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const video = useSelector((state: Selector) => state.video);

    const editor = useSelector((state: Selector) => state.article.editor);
    const articleParts = useSelector((state: Selector) => state.parts);
    const config = useSelector((state: Selector) => state.config.config);
    const selectedLanguage = useSelector((state: Selector) => state.article.editor.selectedLanguage);
    const voiceId = useSelector((state: Selector) => state.voices.thesaurusVoiceId);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);

    const [audioScriptText, setAudioScriptText] = useState('');

    const [playerState, setPlayerState] = useState({ ...playerDefaultState });
    const [streamState, setStreamState] = useState(getStreamDefaultState(selectedLanguage));

    const [audioScriptPlaying, setAudioScriptPlaying] = useState(false);
    const [audioScriptSuggestion, setAudioScriptSuggestion] = useState('');
    const [isGeneratingSuggestion, setIsGeneratingSuggestion] = useState(false);

    // thesaurus voice
    let voice = voiceList[0];
    const [audioScriptVoice, setAudioScriptVoice] = useState<Voice>(voice);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setAudioScriptText(video.parts[0]?.content ?? '');
    }, [video.parts]);

    useEffect(() => {
        setStreamState({
            enabled: true,
            config: config,
            voices: voiceList,
            parts: articleParts,
            selectedPart: editor.selectedPart ? editor.selectedPart : 0,
            selectedLanguage: editor.selectedLanguage,
        });
    }, [config, voiceList, articleParts, editor.selectedPart, editor.selectedLanguage]);

    useEffect(() => {
        if (voiceId && voiceId > -1) {
            setAudioScriptVoice(voiceList.filter(voice => voice.id === voiceId)[0]);
        } else {
            setAudioScriptVoice(voice);
            dispatch(thesaurusVoiceUpdate(voice.id));
        }
    }, [voiceId, dispatch, voiceList, voice]);

    useEffect(() => {
        if (video.parts[0]?.content && video.parts[0]?.content !== audioScriptText) {
            const updatedParts = video.parts.reduce((acc: VideoPart[], part: VideoPart, index: number) => {
                if (index === 0) {
                    acc.push({ ...part, content: audioScriptText });
                } else {
                    acc.push(part);
                }
                return acc;
            }, []);
            dispatch(setAudioScript(updatedParts));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioScriptText, dispatch]);

    const handlePlayPauseAudioScript = () => {
        setAudioScriptPlaying(true);
        handlePlayPauseThesaurus(
            audioScriptText,
            audioScriptVoice.id,
            audioScriptVoice.languageId,
            streamState,
            setPlayerState,
        );
    };

    const handleAudioScriptChange = (e: any) => {
        setAudioScriptText(e.target.value);
    };

    const handleApplySuggestion = () => {
        setAudioScriptText(audioScriptSuggestion);
        handleClose();
    };

    const handleSuggestAudioScript = async () => {
        setIsGeneratingSuggestion(true);
        const suggestedAudioScript = await api.video.suggestAudioScript(audioScriptText);

        const script = await suggestedAudioScript.json();
        setAudioScriptSuggestion(script);

        handleOpen();
        setIsGeneratingSuggestion(false);
    };

    const handleSaveVideo = () => {
        let videoUpdateBody: VideoUpdateBody = generateVideoSaveBody(video);
        if (videoUpdateBody.parts.length > 0) {
            if (videoUpdateBody?.parts[0]?.content !== audioScriptText) {
                const updatedParts = video.parts.reduce((acc: VideoPart[], part: VideoPart, index: number) => {
                    if (index === 0) {
                        acc.push({ ...part, content: audioScriptText });
                    } else {
                        acc.push(part);
                    }
                    return acc;
                }, []);
                videoUpdateBody.parts = updatedParts;

                updateVideo(videoUpdateBody)
                    .then(action => {
                        dispatch(action);
                    })
                    .catch(e => {
                        console.error('Error updating the video properties from AudioScriptBox', e);
                    });
            }
        }
    };

    return (
        <>
            <Box width="100%">
                <Player playerState={playerState} setPlayerState={setPlayerState} />
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 4,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        height: 'auto',
                        padding: 3,
                    }}
                >
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="h6" color="primary" sx={{ mb: 3 }}>
                            Audio script
                        </Typography>

                        <Tooltip title="Audio script assistant">
                            <Box>
                                {isGeneratingSuggestion && <CircularProgress />}
                                {!isGeneratingSuggestion && (
                                    <IconButton
                                        aria-label="AI-assistant"
                                        data-test="btn-AI-assistant"
                                        color="primary"
                                        disabled={audioScriptText === ''}
                                        size="large"
                                        sx={{ mt: -1 }}
                                        onClick={handleSuggestAudioScript}
                                    >
                                        <AutoAwesomeIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Tooltip>
                    </Stack>

                    <Textarea
                        aria-label="maximum height"
                        maxRows={13}
                        minRows={13}
                        name="audioScript"
                        onChange={handleAudioScriptChange}
                        onBlur={handleSaveVideo}
                        placeholder="Type the text for the audio script here ..."
                        value={audioScriptText}
                    />

                    <Stack direction="row" spacing={1} sx={{ mt: 2, justifyContent: 'space-between' }}>
                        <Tooltip title="Play audio script">
                            <Box>
                                <IconButton
                                    aria-label="startPause"
                                    data-test="lexicon-btn-playsource"
                                    onClick={() => handlePlayPauseAudioScript()}
                                    color="primary"
                                    disabled={
                                        audioScriptText === '' ||
                                        (playerState.playing && (!playerState.duration || playerState.isBuffering))
                                    }
                                    size="large"
                                >
                                    {audioScriptPlaying &&
                                    playerState.playing &&
                                    (!playerState.duration || playerState.isBuffering) ? (
                                        <CircularProgress
                                            data-test="lexicon-playsource-progress"
                                            color="secondary"
                                            size={32}
                                        />
                                    ) : audioScriptPlaying && playerState.playing ? (
                                        <PauseCircleIcon data-test="lexicon-playsource-pauseicon" fontSize="inherit" />
                                    ) : (
                                        <PlayCircleFilledWhiteIcon
                                            data-test="lexicon-playsource-playicon"
                                            fontSize="inherit"
                                        />
                                    )}
                                </IconButton>
                            </Box>
                        </Tooltip>

                        <AudioScriptLanguageSelector />
                        <AudioScriptVoiceSelector />
                    </Stack>
                </Box>
            </Box>
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}
            >
                <>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 5,
                            borderRadius: 10,
                            overflowY: 'auto',
                            maxHeight: '95%',
                        }}
                    >
                        <Box>
                            {/* <ModalContent sx={{ width: 400 }}> */}
                            <h2 id="unstyled-modal-title" className="modal-title">
                                Suggested audio script
                            </h2>
                            <p id="unstyled-modal-description" className="modal-description">
                                {audioScriptSuggestion}
                            </p>
                            <Box>
                                <Tooltip title="Use suggestion">
                                    <Box>
                                        <IconButton onClick={handleApplySuggestion}>
                                            <CheckCircleIcon color="primary" />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <Box>
                                        <IconButton onClick={handleClose}>
                                            <CancelIcon color="primary" />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                </>
            </Modal>
        </>
    );
};
const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
`;
